import './App.css';
import img_1 from './img_1.png';
import img_2 from './img_2.png';
import img_3 from './img_3.png';
import img_4 from './img_4.png';
import img_5 from './img_5.png';
import img_6 from './img_6.png';
import img_7 from './img_7.png';
import img_8 from './img_8.png';
import img_9 from './img_9.png';
import img_10 from './img_10.png';
import img_11 from './img_11.png';
import img_12 from './img_12.png';
import img_13 from './img_13.png';
import img_14 from './img_14.png';
import img_15 from './img_15.png';
import img_16 from './img_16.png';
import img_17 from './img_17.png';
import img_18 from './img_18.png';
import img_19 from './img_19.png';
import img_link_preview from './img_link_preview.png';
import 'animate.css';
import React from 'react';
import { Helmet } from 'react-helmet';


let img;


function randomNumberInRange(min, max) {
   return Math.floor(Math.random() * (max - min + 1)) + min;
}

const allImg = [
    img_1,
    img_2,
    img_3,
    img_4,
    img_5,
    img_6,
    img_7,
    img_8,
    img_9,
    img_10,
    img_11,
    img_12,
    img_13,
    img_14,
    img_15,
    img_16,
    img_17,
    img_18,
    img_19
]

function randomImg(){
  img = allImg[randomNumberInRange(0, 18)];
  console.log('Ran randomImg');
}

randomImg();


function App() {
    return (
        <div className="App">
<Helmet>
<meta property="og:title" content="iPhone" />
<meta property="og:image" content="https://i.ibb.co/cYvNkJM/img-link-preview.png"/>
</Helmet>
            <header className="App-header">
                <div className='quokkaImg'>
                    <img src={img} className='randImg'></img>
                </div>
                <div className='text'>
                    <h1>Hey Sarah, Feeling Down?</h1>
                    <h2>This should help. 😊</h2>
                </div>
            </header>
            <body></body>
            {/* <div className='footerWrapper'>
                <footer className='footer'>
                    <a href='imessage:rhetty@me.com&body=Hi%20there.'><button>Talk to me</button></a>
                    <button onClick={() => {imgCounter++;} }>New Image</button>
                    <p>{imgCounter}</p>
                </footer>
            </div> */}
        </div>
    );
}

export default App;